const $ = require("jquery");
$(document).ready(function() {
    $('.switch-language').click(function(e) {
        e.preventDefault();
        if ($(this).hasClass('active')) return;

        let path = "/locale?locale=" + $(this).data('attr-lang');

        $.get(path, function( data ) {
            window.location.reload();
        });
    })
})
